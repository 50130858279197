import axios from "axios";
import Vue from "vue";
// import qs from 'qs'
Vue.prototype.url = "http://119.23.44.146:85/";
axios.defaults.timeout = 3600000; // 响应时间
axios.defaults.baseURL = Vue.prototype.url; // 配置接口地址
axios.defaults.withCredentials = false; // 开启缓存

const common = axios.create({
    headers: {"Content-Type": "application/json;charset=UTF-8"} // 配置请求头
});

const from = axios.create({
    headers: {"Content-Type": "multipart/form-data"} // 配置请求头
});

// 返回一个Promise(发送post请求)
export function formPost(url, params) {
  return new Promise((resolve, reject) => {
    from
      .post(url, params)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}
// 返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    params = JSON.stringify(params);
    common
      .post(url, params)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}
/// /返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    common
      .get(url, { params: param })
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}
export function post(url, params) {
  params = JSON.stringify(params);
  common
    .post(url, params)
    .then(
      response => {
        return response;
      },
      err => {
        return err;
      }
    )
    .catch(error => {
      return error;
    });
}

export default {
  fetchPost,
  fetchGet,
  post,
  formPost
};
