import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  base: "/",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          children: [
            {
              path: "/dashboard",
              name: "dashboard",
              component: () => import("@/view/content/cloudMain/cloudMain.vue")
            },
            {
              path: "materialList",
              name: "materialList",
              component: () =>
                import("@/view/content/material/materialList.vue")
            },
            {
              path: "shopCat",
              name: "shopCat",
              component: () =>
                import("@/view/content/material/materialShopCat.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/groupCenter",
      name: "groupCenter",
      component: () => import("@/view/content/groupCenter/groupCenter.vue")
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error-6.vue")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
